body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222831;
  background-color: #789;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

h1 {
  font-family: Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif;
}

h2 {
  color: #5d7284;
  font-family: Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif;
}

p {
  font-family: Franklin Gothic Medium, Arial Narrow, Arial, sans-serif;
}

.Home {
  text-align: center;
}

.page-content {
  min-width: 360px;
  max-width: 850px;
  z-index: 50;
  background-color: #f8f0e3;
  margin: auto;
  padding-top: 60px;
  padding-bottom: 40px;
  position: relative;
}

.Home-header {
  height: 50px;
  width: 100%;
  z-index: 150;
  background-color: #f8f0e3;
  border-bottom: 2px solid #000;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
}

.contact-info {
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.contact-info .email {
  color: #444;
  margin: auto;
  position: relative;
}

.contact-info .email:hover {
  color: #000;
  margin: auto;
  position: relative;
}

.contact-info .linkedin {
  color: #0a66c2;
  margin: auto auto auto 10px;
  position: relative;
}

.contact-info a {
  font-size: 0;
  text-decoration: none;
}

#home-description {
  width: 400px;
  overflow-wrap: break-word;
  margin: auto;
}

@media (max-width: 640px) {
  #home-description {
    width: 300px;
  }
}

.work-history {
  width: 400px;
  flex-direction: column;
  margin: auto;
  display: flex;
  position: relative;
}

.work-history .triangle {
  width: 6%;
  padding-bottom: 4.26%;
  position: relative;
  left: -30px;
  overflow: hidden;
}

.work-history .triangle:before {
  content: "";
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  background-color: #444;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.work-history .triangle:last-child:before {
  content: "";
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  background-color: #444;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.history-block {
  width: 400px;
  overflow-wrap: break-word;
  text-align: left;
  flex-direction: column;
  display: flex;
  position: relative;
}

.history-block:after {
  content: "";
  width: 4px;
  height: 105%;
  background-color: #444;
  position: absolute;
  left: -20px;
}

.history-block:last-child:after {
  content: "";
  width: 4px;
  height: 100%;
  background-color: #444;
  position: absolute;
  left: -20px;
}

.history-block .content {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  box-shadow: 0 0 5px #000;
}

.history-block .project {
  margin: 0 auto 10px;
}

.history-block .project:last-child {
  margin: 0 auto;
}

.history-block h3 {
  padding-left: 10px;
}

.history-block h4, .history-block h5 {
  margin: 0%;
}

.history-block .info {
  flex-flow: row;
  margin: 0%;
  display: flex;
}

.history-block .info p {
  margin: 0%;
}

.history-block .info .languages {
  margin-left: 10px;
}

.history-block .info .tools {
  margin-left: 50px;
}

.history-block .circle {
  width: 10%;
  z-index: 100;
  position: relative;
  top: 22px;
  left: -28px;
}

.history-block .circle:before {
  content: "";
  width: 14px;
  height: 14px;
  -ms-border-radius: 50%;
  background-color: #f8f0e3;
  border: 3px solid #444;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.history-block .line {
  width: 100%;
  position: relative;
  top: 12px;
  left: -27px;
}

.history-block .line:before {
  content: "";
  width: 4.5%;
  height: 1px;
  background-color: #444;
  padding-bottom: 1%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 640px) {
  .work-history {
    width: 360px;
  }

  .work-history .triangle {
    width: 7%;
    padding-bottom: 4.96%;
    left: 10px;
  }

  .history-block:after, .history-block:last-child:after {
    left: 20px;
  }

  .history-block {
    width: 280px;
    padding-left: 40px;
  }

  .history-block .line:before {
    width: 6%;
    height: 2px;
  }
}

.projects {
  min-width: 360px;
  max-width: 800px;
  flex-direction: column;
  margin: auto;
  display: flex;
  position: relative;
}

.project-block-layout {
  flex-flow: row-reverse wrap-reverse;
  display: flex;
}

.project-block {
  width: 375px;
  overflow-wrap: break-word;
  border: 3px solid #000;
  margin: 0 auto 20px;
  padding-bottom: 10px;
}

.thumbnail {
  border: 1px solid #000;
}

/*# sourceMappingURL=index.818e8894.css.map */
