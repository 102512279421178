#home-description {
  margin: auto;
  width: 400px;
  overflow-wrap: break-word;
}

@media (max-width: 640px) {
  #home-description {
    width: 300px;
  }
}
