.projects {
  margin: auto;
  position: relative;
  min-width: 360px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
}

.project-block-layout {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
}

.project-block {
  margin: 0px auto 20px;
  padding-bottom: 10px;
  width: 375px;
  overflow-wrap: break-word;

  border: 3px solid #000;
}

.thumbnail {
  border: 1px solid #000;
}
