.Home {
  text-align: center;
}

.page-content {
  position: relative;
  margin: auto;
  padding-top: 60px;
  padding-bottom: 40px;
  min-width: 360px;
  max-width: 850px;
  z-index: 50;

  background-color: #f8f0e3;
}
